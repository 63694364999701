import React, {useEffect, useState} from "react";
import {ajaxAdminUserGetBalanceList} from "../../../../api";
import {GetBalanceStatusName, GetCategoryName} from "../../../../utils/define";
import {Button, Form, Modal, Radio, Table, Tag} from "antd";

const formRef = React.createRef()
export default function Balance(props) {
        const [listData, setListData] = useState([]);
        const [listPagination, setListPagination] = useState({showSizeChanger: false});
        const [listLoading, setListLoading] = useState(false);
        const [soSO,setSoSO] = useState({});

        useEffect(() => {
            if (props.visibleInfo.telegram_id){
                formRef.current.setFieldsValue({types:"","category":""})
                initFetch()
            }
            return () => {
                // 清理操作，比如取消网络请求或者清除定时器等
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.visibleInfo.telegram_id]); // 空数组意味着仅在组件挂载时执行这个effect

        const initFetch = () => {
            fetch({telegram_id:props.visibleInfo.telegram_id}).then()
        }

        const fetch = async (params = {}) => {
            setListLoading(true)
            params.page = params.page ? params.page : 1
            console.log(params,"xxxxxxxxxxxxxxxx")
            const result = await ajaxAdminUserGetBalanceList(params)
            if (result.code === 1) {
                const listPaginationNew = { ...listPagination }
                listPaginationNew.total = result.data.total
                listPaginationNew.pageSize = result.data.limit
                listPaginationNew.showTotal = total => `总计 ${total} 条`
                listPaginationNew.current = params.page

                const listData = result.data.list

                setListData(listData)
                setSoSO(params);
                setListLoading(false)
                setListPagination(listPaginationNew)
            }
        }

        const SoSubmit = (values) => {
            setSoSO(values);
            values.page = 1
            values.telegram_id =  props.visibleInfo.telegram_id;
            fetch(values).then()
        }

        const handleTableChange = (pagination) =>{
            soSO.page = pagination.current
            fetch(soSO).then()
        }

    const columns = [
        {
            title: '类型',
            width: 100,
            dataIndex: 'category',
            render: (text, item) => {
                return (
                    <> {GetCategoryName(item.category)} </>
                );
            },
        },{
            title: '原始金额',
            width: 180,
            dataIndex: 'original_price'
        },{
            title: '变更类型',
            width: 120,
            dataIndex: 'amount',
            render: (text, item) => {
                return (
                    <> {item.amount > 0?<Tag color="blue">收入</Tag>:<Tag color="red">支出</Tag>} </>
                );
            },
        },  {
            title: '操作金额',
            width: 180,
            dataIndex: 'amount',
            render: (text, item) => {
                return (
                    <> {item.amount > 0?<Tag color="blue">{item.amount}</Tag>:<Tag color="red">{item.amount}</Tag>} </>
                );
            },
        }, {
            title: '类型',
            width: 150,
            dataIndex: 'type',
            render: (text, item) => {
                return (
                    <> {GetBalanceStatusName(item.type)} </>
                );
            },
        },{
            title: '备注',
            dataIndex: 'notes'
        },  {
            title: '发生时间',
            width: 180,
            dataIndex: 'created_at'
        }]
        return <>
            <Modal open={props.visible} width="70%" title={"余额记录"} forceRender={true} footer={null} onCancel={() => {
                props.setVisible(false)
            }}>
                <Form layout="inline" onFinish={SoSubmit} labelAlign="right" ref={formRef}>
                    <Form.Item label="余额类型" name="category">
                        <Radio.Group  >
                            <Radio value="">全部</Radio>
                            <Radio value="1">TRX</Radio>
                            <Radio value="2">USDT</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="变更类型" name="types">
                        <Radio.Group >
                            <Radio value="">全部</Radio>
                            <Radio value="1">收入</Radio>
                            <Radio value="2">支出</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                        <Button htmlType="button" style={{ marginBottom: 0, marginLeft: 5 }} onClick={() => {
                            formRef.current.resetFields();
                        }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
                <hr/>
                    <Table
                        size="small"
                        columns={columns}
                        rowKey="id"
                        pageSize={15}
                        dataSource={listData}
                        pagination={listPagination}
                        loading={listLoading}
                        onChange={handleTableChange}
                    />
            </Modal>
        </>
}