import {Button, Card, Col, Descriptions, Form, Input, message, Modal, Row, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {GetStatusName} from "../../../../utils/define";
import {
    ajaxAdminConfigGetGroupList,
    ajaxAdminConfigGetGroupPush,
    ajaxAdminUserSetTelegramGroupSend
} from "../../../../api";
import Editor from "../../../../components/editor";

const formSendRef = React.createRef()
export default function TelegramGroup(){
    const [listLoading, setListLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleInfo, setVisibleInfo] = useState({});

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        const result = await ajaxAdminConfigGetGroupList(params)
        if (result.code === 1) {
            setListData(result.data.list)
        }
        setListLoading(false)
    }
    const click = async () => {
        setListLoading(true)
        const result = await ajaxAdminConfigGetGroupPush()
        if (result.code === 1) {
            fetch().then()
            setListData(listData)
        }
        setListLoading(false)
    }

    const SendSubmit = async (values) => {
        const res = await ajaxAdminUserSetTelegramGroupSend(values)
        if (res.code === 1) {
            message.success(res.msg)
        }
    }

    const columns = [
        {
            title: '群组 ID',
            dataIndex: 'chat_id',
        },  {
            title: '群组名称',
            dataIndex: 'chat_name',
        },  {
            title: '状态',
            dataIndex: 'status',
            render: (text, item) => {
                return (
                    <> {GetStatusName(item.status)} </>
                );
            },
        },   {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            formSendRef.current.setFieldsValue(item)
                            setVisibleInfo(item)
                            setVisible(true)
                        }}>发送消息</Button>
                    </Space>
                );
            },
        }]

    return <>
        <Card title="列表" extra={<Button style={{width:240}} onClick={click} loading={listLoading} type="primary" htmlType="submit">拉取消息</Button>}  >
            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                loading={listLoading}
            />
        </Card>
        <Modal open={visible} width="70%" title={"发送消息"} forceRender={true} footer={null} onCancel={() => {
            setVisible(false)
        }}>
            <Descriptions title="" size="small">
                <Descriptions.Item label="群组ID">{visibleInfo.chat_id}</Descriptions.Item>
                <Descriptions.Item label="群组名称">{visibleInfo.chat_name}</Descriptions.Item>
            </Descriptions>
            <br/><br/>
            <Form onFinish={SendSubmit} labelAlign="right" ref={formSendRef}>
                <Form.Item name="chat_id" hidden={true}><Input /></Form.Item>
                <Form.Item label="消息内容" name="content">
                    <Editor onChange={(eee)=>{
                        formSendRef.current.setFieldsValue({content:eee})
                    }}/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                发送
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}