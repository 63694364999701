import React from 'react';
import {Route, Routes} from "react-router-dom";
import PublicLogin from "../../public/login/login";

export default function Index(){
    return <>
        <Routes>
            <Route path='/login' element={<PublicLogin/>}/>
        </Routes>
    </>
}