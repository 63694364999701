import React, {useEffect, useState} from "react";
import {LockOutlined, SafetyCertificateOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, message, Row, Space} from "antd";
import DocumentTitle from "react-document-title";
import "./login.css";
import storageUtils from "../../../utils/storage";
import {ajaxLoginGetCode, ajaxPublicLogin} from "../../../api";
import {useNavigate} from "react-router-dom";


export default function Login(){
    const [imageBase64, setImageBase64] = useState("");
    const [code, setCode] = useState("");
    const navigate = useNavigate()
    useEffect(() => {
        initFetch()
        getCode().then()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        storageUtils.removeToken();
        storageUtils.removeUser();
        storageUtils.removeXzqh();
        storageUtils.removeXzqhName();
    }

    const getCode = async () => {
        let res = await ajaxLoginGetCode()
        if (res.code === 1) {
            setImageBase64(res.data.img_data)
            setCode(res.data.captcha_id)
        }
    }

    const onFinish = async (values) => {
        const result = await ajaxPublicLogin({...values, captcha_id: code});
        if (result.code === 1) { // 登陆成功
            // 提示登陆成功
            const info = result.data.info || {}
            message.success('登陆成功')
            // 保存user
            const user = result.data
            storageUtils.saveToken(user.token, user.expire) // 保存到local中
            storageUtils.saveUser({
                'username': values.name,
                'menuList': [],
                'info': info,
                'config': {noticeStatus: 1}
            }); //存储用户资料
            navigate(`/admin`)
        } else {
            await getCode()
        }
    }

    //对密码进行自定义认证
    const validatePwd = async (rule, value = '') => {
        if (!value) {
            return Promise.reject('密码必须输入');
        } else if (value.length < 4) {
            return Promise.reject('密码长度不能小于4位');
        } else if (value.length > 32) {
            return Promise.reject('密码长度不能大于32位');
        } else if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
            return Promise.reject('密码必须是英文、数字或下划线组成');
        }
        Promise.resolve()
    }


    return (
        <Row className="layout">
            <div className="layout-center" style={{margin: "0 auto", height: '100vh', display: 'flex'}}>
                <DocumentTitle title="登录"/>
                <div className="title-img">
                    <img src="/logo192.png" alt=""/>
                    <div className='title-img-words'>
                        <div style={{fontSize: '40px', fontWeight: '800'}}>管理后台</div>
                    </div>
                </div>
                <div className="box">
                    <div className="center-ner">
                        <div className={"head"}>用户登录</div>
                        <Form onFinish={onFinish} initialValues={{remember: true}} className="form" >
                            <Form.Item name="username" rules={[
                                {required: true, message: '账户必须输入'},
                                {min: 4, message: '账户至少4位'},
                                {max: 32, message: '账户至多32位'},
                                {pattern: /^[a-zA-Z0-9_-]+$/, message: '账户必须是英文,数字,下划线,减号'},
                            ]}>
                                <Input className="input" placeholder="请输入用户名" prefix={<UserOutlined className="site-form-item-icon" style={{color: "rgba(0,0,0,.25)"}}/>}/>
                            </Form.Item>
                            <Form.Item name="password" rules={[{validator: validatePwd}]}>
                                <Input.Password className="input" placeholder="请输入密码" prefix={<LockOutlined className="site-form-item-icon" style={{color: "rgba(0,0,0,.25)"}}/>}/>
                            </Form.Item>
                            <Form.Item name="captcha" rules={[{required: true, message: '验证码必须输入'}]}>
                                <Space>
                                    <Input className="input" placeholder="请输入验证码" prefix={<SafetyCertificateOutlined className="site-form-item-icon" style={{color: "rgba(0,0,0,.25)"}}/>}/>
                                    <img alt={''} src={imageBase64} onClick={() => {
                                        getCode()
                                    }} style={{width: '100px', height: '40px'}}/>
                                </Space>
                            </Form.Item>
                            <Form.Item>
                                <Button block="true" type="primary" htmlType="submit" className="btn">马上登录</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </Row>
    );
}