import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, InputNumber, message, Modal, Radio, Row, Space, Table} from "antd";
import {GetCategoryName, GetStatusName} from "../../../../../utils/define";
import {ajaxAdminRechargeGetPackageList, ajaxAdminRechargeSetPackageSave} from "../../../../../api";

const formRef = React.createRef()
export default function RechargePackageList(props){
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);
    const [operate, setOperate] = useState("添加");


    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect


    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminRechargeGetPackageList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list

            setListData(listData)
            setSoSO(params);
            setListLoading(false)
            setListPagination(listPaginationNew)
        }
    }

    async function Submit(values) {
        let result = await ajaxAdminRechargeSetPackageSave(values)
        if (result.code === 1) {
            initFetch()
            setVisible(false);
            message.success('操作成功')
        }
    }

    // const SoSubmit = (values) => {
    //     setSoSO(values);
    //     values.page = 1
    //     fetch(values).then()
    // }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }

    const columns = [
        {
            title: '套餐ID',
            dataIndex: 'id',
        },  {
            title: '套餐名称',
            dataIndex: 'name',
        },  {
            title: '套餐类型',
            dataIndex: 'category',
            render: (text, item) => {
                return (
                    <> {GetCategoryName(item.category)} </>
                );
            },
        },  {
            title: '金额',
            dataIndex: 'amount'
        },  {
            title: '状态',
            dataIndex: 'status',
            render: (text, item) => {
                return (
                    <> {GetStatusName(item.status)} </>
                );
            },
        },   {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            setOperate("编辑")
                            formRef.current.setFieldsValue(item)
                            setVisible(true)
                        }}>编辑</Button>
                    </Space>
                );
            },
        }]

    return <>
        <Card  title="列表">
            <Button type="primary" style={{marginBottom: 15, width: 160}} onClick={()=>{
                setOperate("添加")
                formRef.current.resetFields()
                setVisible(true);
            }}> 添加 </Button>

            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>


        <Modal open={visible} width={700} title={operate} forceRender={true} footer={null} onCancel={() => {setVisible(false)}}>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={Submit}  ref={formRef} {...props.layout}>
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item label="套餐名称" name="name">
                    <Input placeholder={'套餐名称不能为空'}/>
                </Form.Item>
                <Form.Item label="套餐类型" name="category">
                    <Radio.Group value={0}>
                        <Radio value={0}>TRX</Radio>
                        <Radio value={1}>USDT</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="金额" name="amount">
                    <InputNumber min={1} max={999} defaultValue={3}   />
                </Form.Item>
                <Form.Item label="状态" name="status">
                    <Radio.Group value={1}>
                        <Radio value={0}>关闭</Radio>
                        <Radio value={1}>开启</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                保存
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}