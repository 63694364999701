/**
 * 本地存储数据
 */
import Store from "store2"

const exportedObject = {
    saveToken(token, token_expire) {
        Store.set('admin_user_token', token, token_expire)
        Store.set('admin_user_token_expire', token_expire, token_expire)
        return true;
    }, getToken() {
        return Store.get('admin_user_token') || ''
    }, getTokenExpire() {
        return Store.get('admin_user_token_expire') || ''
    }, removeToken() {
        Store.remove('admin_user_token')
        Store.remove('admin_user_token_expire')
        return true;
    }, saveUser(user) {
        Store.set('admin-user', user)
        return true
    }, getUser() {
        return Store.get('admin-user') || ''
    }, removeUser() {
        Store.remove('admin-user')
        return true
    }, saveXzqh(xzqh) {
        Store.set('admin-xzqh', xzqh)
        return true
    }, getXzqh() {
        return Store.get('admin-xzqh') || ''
    }, removeXzqh() {
        Store.remove('admin-xzqh')
        return true
    }, saveXzqhName(xzqh) {
        Store.set('admin-xzqh-name', xzqh)
        return true
    }, getXzqhName() {
        return Store.get('admin-xzqh-name') || ''
    }, removeXzqhName() {
        Store.remove('admin-xzqh-name')
        return true
    }, saveConfig(conifg) {
        Store.set('admin-config', conifg)
        return true
    }, getConfig() {
        return Store.get('admin-config') || {}
    }, removeConfig() {
        Store.remove('admin-config')
        return true
    }, getConfigCosSts() {
        return Store.get('config_cos_sts') || ''
    }, setConfigCosSts(value) {
        Store.set('config_cos_sts', value)
    }, removeConfigCosSts(param) {
        Store.remove(param)
        return true
    }, getKey(param) {
        return Store.get(param) || ''
    }, setKey(param, value) {
        Store.set(param, value)
    }, removeKey(param) {
        Store.remove(param)
        return true
    },
}

export default exportedObject