import axios from "axios"
import Qs from "qs" //字符处理模块
import storageUtils from "../utils/storage";
import {message, Modal} from "antd";

export function ajax(url, param = {}, type = 'GET') {
    param.token = storageUtils.getToken()
    axios.defaults.headers = {
        'Content-type': 'application/x-www-form-urlencoded',
        "Access-Control-Allow-Origin": "*",
    }
    return new Promise((resolve, reject) => {
        let promise = ''
        if (type === 'GET') {
            promise = axios.get(url, {params: param})
        } else {
            promise = axios.post(url, Qs.stringify(param))
        }
        //如果成功了, 调用resolve(value)
        promise.then(response => {
            if (response.data.code === 11) {
                message.error('错误: ' + response.data.msg)
            } else if (response.data.code === 90) {
                storageUtils.removeToken()
                //message.error('登陆超时')
                Modal.error({
                    title: '登录错误',
                    content: response.data.msg,
                    keyboard: false,
                    okText: '重新登录',
                    onOk() {
                        window.location.replace(`/public/login`);
                    },
                });
                return false;
            }
            resolve(response.data)
        }).catch(error => {
            message.error(error.message === 'Network Error' ? '网络无法链接' : error.message)
            resolve(error.message)
        })
    })
}

export function requestBase(url, data, type) {
    axios.defaults.headers = {
        'Content-type': 'application/x-www-form-urlencoded',
    };

    return new Promise((resolve, reject) => {
        let promise;
        if (type === 'GET') {
            promise = axios.get(url, { params: data })
        } else {
            promise = axios.post(url, Qs.stringify(data))
        }
        promise.then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error);
        })
    })
}

