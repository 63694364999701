import React, {useEffect, useState} from "react";
import {ajaxAdminConfigSetEd, ajaxAdminTenancyGetOrderAdd, ajaxAdminTenancyGetOrderList} from "../../../../../api";
import {
    GetCategoryName,
    GetTenancyStatusName,
    GetValidateAddress,
    GetValidateNumber
} from "../../../../../utils/define";
import {Button, Card, Col, Form, Input, InputNumber, message, Modal, Radio, Row, Space, Table} from "antd";


export default function TenancyOrderList(props){
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);
    const [visibleAdd, setVisibleAdd] = useState(false);


    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminTenancyGetOrderList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list

            setListData(listData)
            setSoSO(params);
            setListLoading(false)
            setListPagination(listPaginationNew)
        }
    }

    // const SoSubmit = (values) => {
    //     setSoSO(values);
    //     values.page = 1
    //     fetch(values).then()
    // }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }

    const addClick = () =>{
        setVisibleAdd(true)
    }

    const addSubmit = async (values) => {
        const res = await ajaxAdminTenancyGetOrderAdd(values)
        if (res.code === 1) {
            setVisibleAdd(false)
            message.success('保存修改成功')
        }
    }

    const columns = [
         {
            title: '用户',
            dataIndex: 'telegram_id',
            render: (text, item) => {
                return (
                    <>{item.telegram_id}（{item.user_name}） </>
                );
            }
        },  {
            title: '充值类型',
            dataIndex: 'category',
            render: (text, item) => {
                return (
                    <> {GetCategoryName(item.category)} </>
                );
            },
        },  {
            title: '充值金额',
            dataIndex: 'amount'
        },{
            title: '收货方地址',
            dataIndex: 'to_address'
        }, {
            title: '总笔数',
            dataIndex: 'number'
        }, {
            title: '使用笔数',
            dataIndex: 'number_surplus'
        }, {
            title: '状态',
            dataIndex: 'status',
            render: (text, item) => {
                return (
                    <> {GetTenancyStatusName(item.status)} </>
                );
            },
        },   {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            setVisible(true)
                        }}>详情</Button>
                    </Space>
                );
            },
        }]
    return <>
        <Card title="列表"  extra={<Button style={{width:240}} onClick={addClick} loading={listLoading} type="primary" htmlType="submit">添加新的订单</Button>}>
            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>
        <Modal open={visible} width={700} title={"详情"} forceRender={true} footer={null} onCancel={() => {setVisible(false)}}>
            不知道写点什么
        </Modal>

        <Modal open={visibleAdd} width={700} title={"添加订单"} forceRender={true} footer={null} onCancel={() => {setVisibleAdd(false)}}>
            <Form labelCol={{span:6}} wrapperCol={{span:18}} onFinish={addSubmit}  >
                <Form.Item label="用户TelegramId" name="telegram_id">
                    <Input />
                </Form.Item>
                <Form.Item label="用户名称" name="user_name">
                    <Input />
                </Form.Item>
                <Form.Item label="收货方地址" name="to_address" rules={GetValidateAddress()}>
                    <Input />
                </Form.Item>
                <Form.Item label="笔数" name="number">
                    <InputNumber min={1} max={999}  />
                </Form.Item>
                <Form.Item label="剩余笔数" name="number_surplus">
                    <InputNumber min={0} max={999}  />
                </Form.Item>
                <Form.Item label="金额类型" name="category" rules={[{required: true, message: '不能为空'}]}>
                    <Radio.Group >
                        <Radio value="1">TRX</Radio>
                        <Radio value="2">USDT</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="金额" name="amount" rules={[{required: true, message: '不能为空'}]}>
                    <Input />
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                保存
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}